import React from 'react';
import './App.css';

import Resume from './Resume.js';
import About from './About.js';
import Coursework from './Coursework.js';
import Demonstrations from './Demonstrations.js';
import Code from './Code.js';

import {Alignment, AnchorButton, FocusStyleManager, Navbar} from "@blueprintjs/core";
import {BrowserRouter as Router, Redirect, Route, Link, Switch} from "react-router-dom";

FocusStyleManager.onlyShowFocusOnTabs();

function DoriaButton(props)
{
  return <Link className="bp3-button bp3-minimal" role="button" to={props.target}>{props.text}</Link>
}

function App() {
  return (
    <div>
      <Router>
        <Navbar>
          <Navbar.Group align={Alignment.LEFT}>
            <DoriaButton target="/" text="Resume" />
            <DoriaButton target="/coursework.html" text="Coursework" />
            <DoriaButton target="/code.html" text="Code" />
            <DoriaButton target="/demonstrations.html" text="Demonstrations" />
            <DoriaButton target="/about.html" text="About" />
          </Navbar.Group>
        </Navbar>
        <div style={{margin: "1em 3ex 1em 3ex"}}>
          <Switch>
            <Route exact strict path="/">
              <Redirect to="/resume.html" />
            </Route>
            <Route exact strict path="/resume.html" component={Resume} />
            <Route exact strict path="/coursework.html" component={Coursework} />
            <Route exact strict path="/code.html" component={Code} />
            <Route exact strict path="/about.html" component={About} />
            <Route exact strict path="/demonstrations.html" component={Demonstrations} />
            <Route exact strict path="/checkers.html" component={Demonstrations} />
            <Route exact strict path="/countdown.html" component={Demonstrations} />
            <Route exact strict path="/shooter.html" component={Demonstrations} />
            <Route component={Default} />
          </Switch>
        </div>
        <Navbar>
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>stevedoria.net</Navbar.Heading>
            <Navbar.Divider />
            <AnchorButton href="https://blog.stevedoria.net/" minimal={true}>blog</AnchorButton>
            <AnchorButton href="https://solutions.stevedoria.net/" minimal={true}>solutions</AnchorButton>
            <AnchorButton href="https://www.stevedoria.net/" minimal={true}>www</AnchorButton>
          </Navbar.Group>
        </Navbar>
      </Router>
    </div>
  );
}

function Default()
{
  return (
    <div>Oops. Not Found.</div>
  );
}

export default App;
