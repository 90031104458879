import React from 'react';

function About()
{
    return (
        <div>
            <style>{`
                @media screen and (max-width: 600px) {
                    #about_image { 
                        display: none;
                    }
                }
                @media screen and (min-width: 600px) {
                    #about_image { 
                        width: 33%;
                        max-width: 254px;
                        float: right;
                    }
                }
            `}</style>
            <img id="about_image" src="/img/atrium.jpg" style={{padding: "1em 2ex 1em 2ex"}} alt="" />
            <div className="bp3-text-large" style={{textAlign: "justify"}}>
                <p><h2>About This Site</h2></p>
                <p>This site presents information about Steve A. Doria to potential employers. It features code snippets, a listing of taken classes, and small games that were developed independently.</p>
                <p><h2>About the Implementation of Games</h2></p>
                <p>The implementation of computer games deals with computationally hard problems. Computer entertainment software also uses computing resources intensively. Not only do games perform collision detection between objects and simulate physics, they anticipate the actions of game players and have computer-controlled characters act appropriately. These software applications are usually required to operate within a fixed environment with a restricted amount of memory, and they must do it quickly enough to keep players immersed in the game worlds that these applications provide. To develop games, implementers must define and understand software requirements and translate those requirements into feasible implementations.</p>
                <p><h2>General Applicability of Game Programming Skills</h2></p>
                <p>The skills required by game development for tasks such as requirements acquisition, software specification and design, implementation, and testing are used in all software development endeavors. Hopefully, the applications that were written for this site makes Steve A. Doria's possession of these skills evident.</p>
                <p><h2>About Steve A. Doria</h2></p>
                <p>Steve A. Doria is a software programmer, who possesses the required programming skills and can readily acquire the desired ones. He desires a software developer position, and he is interested in an employer that will provide him with an opportunity to further his career and excel.</p>
            </div>
        </div>
    );
}

export default About;