import React from 'react';
import {Pre} from '@blueprintjs/core';

function Code()
{
    return (
        <div>
            <style>{`
                .bp3-code-block {font-size: 0.8em;}
            `}</style>
            <CodeSnippetA />
            <CodeSnippetB />
        </div>
    );
}

function CodeSnippetA(props)
{
    return (
        <Pre class="code_snippet">{`
/*
    Steve A. Doria
    June 13, 2004

    Utility that outputs the hexadecimal
    value of each byte in the input file
    to stdout.
*/
    
#include <fstream>
#include <iostream>
#include <iomanip>

const int LINE_LENGTH = 16;

int main( int argc, char * argv[] )
{
    using std::cout;
    using std::ifstream;
    using std::ios_base;
    using std::setw;
    using std::setfill;
    using std::hex;
    using std::uppercase;

    if( argc < 2 || argc >= 3 )
    {
        cout << "usage: "
            << argv[0] << " <file>\\n";
    }
    else
    {
        char c;
        int i = 0;
        ifstream f(argv[1], ios_base::binary);

        while( f.read(&c,sizeof(c)) )
        {
            if( LINE_LENGTH/2==i )
                cout << "- ";

            if( LINE_LENGTH==i )
            {
                cout << "\\n";
                i = 0;
            }

            cout << setw(2) << setfill('0')
                << hex << uppercase
                << unsigned(c & 0xFF) << " ";
            ++i;
        }
    }
    return 0;
}
    `}</Pre>
    );
}

function CodeSnippetB(props)
{
    return (
        <Pre class="code_snippet">{`
/*
    Idle rant in code.
*/

#include <windows.h>

#include <stdlib.h>
#include <time.h>

int WINAPI WinMain(
    HINSTANCE, HINSTANCE, PSTR, int )
{
    srand(time(0));

    int width = GetSystemMetrics(SM_CXSCREEN),
        height = GetSystemMetrics(SM_CYSCREEN);

    while( true )
    {
        HDC hdc = GetDC(NULL);
        SetPixel(
            hdc,
            rand() % width,
            rand() % height,
            RGB(
                rand() % 255,
                rand() % 255,
                rand() % 255
            )
        );
        ReleaseDC(NULL, hdc);
    }
    return 0;
}
    `}</Pre>
    );
}

export default Code;