import React from 'react';

function Course(props)
{
    return (
        <li>{props.number}: {props.name}</li>
    )
}

function Quarter(props)
{
    return (
            <div class="coursework_quarter">
                <big style={{fontWeight: "bold"}}>{props.period}</big><br />
                <ul>
                {props.children}
                </ul>
            </div>
    );
}

function Year(props)
{
    return (
        <tr>
            <td style={{verticalAlign: "top"}}>
            {props.children}
            </td>
        </tr>
    );
}

function Coursework()
{
    return (
        <div style={{width: "100%"}}>
            <style>
                {`
                @media screen and (max-width: 600px) {
                    .coursework_quarter { 
                        width: 100%; float: left;
                    }
                }
                @media screen and (min-width: 600px) {
                    .coursework_quarter { 
                        width: 33%; float: left;
                    }
                }
                `}
            </style>
            <table style={{textAlign: "left"}}>
                <Year>
                        <Quarter period="Fall 1998">
                            <Course number="ICS 21" name="Introduction to Computer Science I" />
                            <Course number="MATH 6A" name="Discrete Mathematics for Computer Science" />
                            <Course number="WOMN ST 50A" name="Gender and Feminism" />
                        </Quarter>
                        <Quarter period="Winter 1999">
                            <Course number="ICS 22" name="Introduction to Computer Science II" />
                            <Course number="MATH 6B" name="Boolean Algebra / Logic" />
                            <Course number="WOMN ST 50B" name="Reproducing and Resisting Inequality" />
                        </Quarter>
                        <Quarter period="Spring 1999">
                            <Course number="ICS 23" name="Fundamental Data Structures" />
                            <Course number="ICS 51" name="Introductory Computer Organization" />
                            <Course number="MATH 6C" name="Linear Algebra" />
                            <Course number="WOMN ST 50C" name="Gender and Popular Culture" />
                        </Quarter>
                </Year>
                <Year>
                        <Quarter period="Fall 1999">
                            <Course number="ICS 52" name="Introduction to Software Engineering" />
                            <Course number="MATH 2A" name="Differential Calculus" />
                            <Course number="WR 39B" name="Expository Writing" />
                        </Quarter>
                        <Quarter period="Winter 2000">
                            <Course number="ICS 151" name="Digital Logic Design" />
                            <Course number="MATH 2B" name="Integral Calculus" />
                            <Course number="WR 39C" name="Argument and Research" />
                            <Course number="ANTHRO 2A" name="Introduction to Sociocultural Anthropology" />
                        </Quarter>
                        <Quarter period="Spring 2000">
                            <Course number="ICS 121" name="Software Tools and Methods" />
                            <Course number="ICS 141" name="Programming Languages" />
                            <Course number="MATH 2C" name="Infinite Series and Three-Dimensional Geometry" />
                            <Course number="BIO SCI 35" name="The Brain and Behavior" />
                        </Quarter>
                </Year>
                <Year>
                    <Quarter period="Fall 2000">
                        <Course number="ICS 161" name="Design and Analysis of Algorithms" />
                        <Course number="ICS 171" name="Introduction to Artificial Intelligence" />
                        <Course number="JAPANSE 1A" name="Fundemental Japanese I" />
                        <Course number="BIO SCI 1A" name="Life Sciences I" />
                    </Quarter>
                    <Quarter period="Winter 2001">
                        <Course number="ICS 142" name="Compilers and Interpreters" />
                        <Course number="ICS 153" name="Computer Networks" />
                        <Course number="JAPANSE 1B" name="Fundamental Japanese II" />
                        <Course number="BIO SCI 1B" name="Life Sciences II" />
                    </Quarter>
                    <Quarter period="Spring 2001">
                        <Course number="ICS 152" name="Computer System Architectures" />
                        <Course number="ICS 143" name="Principles of Operating Systems" />
                        <Course number="ICS 139" name="Technical Writing and Communication Skills" />
                        <Course number="JAPANSE 1C" name="Fundamental Japanese III" />
                    </Quarter>
                </Year>
                <Year>
                    <Quarter period="Fall 2001">
                        <Course number="ICS 156" name="Advanced Computer Networks" />
                        <Course number="ICS 155A" name="Logic Design Laboratory" />
                        <Course number="MATH 105A" name="Numerical Analysis" />
                        <Course number="JAPANSE 2A" name="Intermediate Japanese I" />
                    </Quarter>
                    <Quarter period="Winter 2002">
                        <Course number="ICS 143" name="Project in Operating Systems" />
                        <Course number="ICS 131" name="Social Analysis of Computerization" />
                        <Course number="MATH 171A" name="Linear Programming" />
                        <Course number="JAPANSE 2B" name="Intermediate Japanese II" />
                    </Quarter>
                    <Quarter period="Spring 2002">
                        <Course number="ICS 123" name="Software Architecture, Distributed Systems, and Interoperability" />
                        <Course number="MATH 171B" name="Nonlinear Programming" />
                        <Course number="JAPANSE 2C" name="Intermediate Japanese III" />
                    </Quarter>
                </Year>
            </table>
        </div>
    );
}

export default Coursework;