import React from 'react';
import {Link, Route, Switch} from "react-router-dom";

import {ButtonGroup, Card, H2, HTMLTable} from "@blueprintjs/core";

function Shooter(props)
{
    return (
        <HTMLTable>
            <tr>
                <td valign="top" style={{verticalAlign: "top", textAlign: "center", width: "40%"}}>
                    <img src="/img/shooter.jpg" width="100%" alt="Shooter Application" /><br />
                    <div style={{fontSize: "80%"}}>
                        Shooter implemented with DirectX 9.0b<br />
                        Written by Steve A. Doria<br />
                    </div>
                </td>
                <td style={{verticalAlign: "top", width: "60%", paddingLeft: "3e"}}>
                    <H2>Shooter</H2>
                    <p>This Win32 application demonstrates the use of the DirectInput and Direct3D intefaces. It features simple physics and collision detection. Shooter relies on the mouse device as the sole means of controlling the avatar, which is represented by the circle. A change in the mouse device's position causes a change in the avatar's velocity. Using the primary mouse device button causes the avatar to emit objects, which bounce off the walls and affect the avatar's color upon colliding with the avatar.</p>
                    <p>This application is the product of my first attempt at writing an interactive graphical program.</p>
                    <p>The application requires DirectX 9.0b or later. The binary executable can be found here: <a href="bin/shooter.exe">shooter.exe</a>.</p>
                </td>
            </tr>
        </HTMLTable>
    );
}

function Checkers(props)
{
    return (
        <HTMLTable>
            <tr>
                <td valign="top" style={{verticalAlign: "top", textAlign: "center", width: "40%"}}>
                    <img src="/img/checkers.png" width="100%" alt="Checkers Application Screenshot" /><br />
                    <div style={{fontSize: "80%"}}>
                        Checkers implemented with Java2SDK [v1.4.2_04]<br />
                        Written by Steve A. Doria<br />
                    </div>
                </td>
                <td style={{verticalAlign: "top", width: "60%", paddingLeft: "3e"}}>
                    <H2>Checkers</H2>
                    <p>This Java application demonstrates the minimax search algorithm with alpha-beta pruning. The player's pieces are red, and the computer's are black. The primary mouse button is used to select a piece. The secondary mouse button cancels the selection. After a piece is selected, the user selects a square and the piece is moved.</p>
                    <p>This application complies with the rules for tourneyment Checkers, which can be easily found on the Internet. Both players are forced to capture their  opponent's pieces at every opportunity. A loss occurs when a player is  unable to make a move. This includes times when a player has no remaining pieces or when all pieces are blocked and prevented from moving.</p>
                    <p>Running this application requires the appropriate Java Runtime Environment. This application is available here: <a href="/bin/CheckersApp.jar">CheckersApp.jar</a></p>
                </td>
            </tr>
        </HTMLTable>
    );
}

function Countdown(props)
{
    return (
        <HTMLTable>
            <tr>
                <td valign="top" style={{verticalAlign: "top", textAlign: "center", width: "40%"}}>
                    <div style={{fontSize: "80%"}}>
                        <img src="/img/countdown.png" width="100%" alt="Countdown Application Screenshot" /><br />
                        Countdown implemented with HTML, JavaScript, CSS<br />
                        Written by Steve A. Doria<br />
                    </div>
                </td>
                <td style={{verticalAlign: "top", width: "60%", paddingLeft: "3e"}}>
                    <H2>Countdown</H2>
                    <p>This demonstration presents the use of JavaScript and Cascading Style Sheets (CSS) in the implementation of a simple counter. It displays the time that is provided by the Internet browser's host operating system as the current time. The page uses text to also present the time interval that is being considered. The graph serves to aid in visualizing the progression through the interval.</p>
                    <p>This demonstration has been tested on recent versions of Google Chrome, Mozilla Firefox, and Microsoft Internet Explorer.</p>
                    <p>A live demonstration can be found here: <a href="/bin/countdown.html">countdown.html</a></p>
                </td>
            </tr>
        </HTMLTable>
    );
}

function DefaultDemonstrationContent(props)
{
    return (
        <div>
        <h2>{props.title}</h2>
        <div className="bp3-text-large" style={{textAlign: "justify"}}>{props.children}</div>
        </div>
    )
}
function DefaultDemonstration(props)
{
    return (
        <div>
            <DefaultDemonstrationContent title="The Staffing Problem">
                Finding the perfect team member is difficult. There are just too many candidates
                to consider, and their resumes make them all appealing. Choosing the wrong
                candidate results in time wasted on familiarizing the new employee with the
                company's problems, practices, and goals. Time is also wasted when the candidate
                decides to leave the company. The company needs time to find a replacement, and
                time is used to familiarize the replacement with the former employee's work to
                continue operations and development.
            </DefaultDemonstrationContent>
            <DefaultDemonstrationContent title="Problems with Employing Optimal and Superoptimal Candidates">
                Human resources representatives tend to bloat their job listings with superfluous job requirements, possibly to find a single candidate that can perform the responsibilities that are usually distributed among several people. If the job requirements are reasonable, then there exist people who possess the specified job requirements. Unfortunately, other companies also exist that desire the same candidate, and a subset of these companies can provide opportunities that attract employees away from other companies. Some candidates choose to pursue those opportunities even while being gainfully employed, because their current employer could not provide the room needed for professional growth, does not sufficiently challenge the employee to motivate self-improvement, or no longer offers the excitement of learning and joy of accomplishment as is often sought by programmers.
            </DefaultDemonstrationContent>
            <DefaultDemonstrationContent title="Suboptimal Alternatives">
                There exists people who do not possess all the specified job requirements and make themselves candidates by filling out employment applications, presenting resumes, and interviewing. These people are typically filtered from continuing the employment process simply because the skills listed on a candidate's resume do not contain all those listed on the job posting, or because they did not perform well on an examination or correctly answer conjured, non-practical interview questions. These people happen to be a better investment than optimal and superoptimal candidates, because they tend to be excited about the new knowledge they find in solving problems they have never before faced. They have room for promotion within the company, and they will be dedicated to the company that has received their contributions, which they believe are significant.
            </DefaultDemonstrationContent>
            <DefaultDemonstrationContent title="Motivation for Presenting Demonstrations">
                I present demonstrations to help me stand out from other candidates. The demonstrations were not written to fulfill class requirements. They were written independently, and thereby evidence my self-motivation and interest in writing software. They exemplify my ability to use different tools effectively and my drive to learn. Checkers is implemented using Java and its primitive graphics programming interface, and Shooter is implemented in C++ with Direct3D and DirectInput. Like this site, the demonstrations are not targetted to people who are dazzled by fanfare, buzzwords, and the latest technological methods. The site and these demonstrations are intended for visionaries who can see the foundational skills presented and how these skills can be guided and used to further their objectives.
            </DefaultDemonstrationContent>
            <DefaultDemonstrationContent title="Steve A. Doria as Part of a Staffing Solution">
            I understand that I do not possess all the required skills for most programming positions. I am, however, highly motivated in learning. I love solving problems, and I enjoy knowing that other people are using my solutions. With a strong sense of job security, I will develop applications that will make my tasks and those of others easier to perform. I intend to write small applications that automate simple job functions to make myself available to more challenging company problems and to enhance existing solutions for problems of the past. I will operate in a manner that is consistent with the goals of my employers, and I am dedicated to helping my employers remain highly competitive. I would expect the same from employees of my own company, and I offer to do the same for yours.
            </DefaultDemonstrationContent>
        </div>
    );
}

function DemonstrationsButton(props)
{
    return (
        <Link className="bp3-button bp3-minimal" style={{justifyContent: "left"}} to={props.target}>{props.text}</Link>
    );
}

function Demonstrations(props)
{
    return (
        <div>
            <ButtonGroup alignText="right">
                <DemonstrationsButton target="/checkers.html" text="Checkers" />
                <DemonstrationsButton target="/countdown.html" text="Countdown" />
                <DemonstrationsButton target="/shooter.html" text="Shooter" />
            </ButtonGroup>
            <Card>
            <Switch>
                <Route exact strict path="/checkers.html" component={Checkers} />
                <Route exact strict path="/countdown.html" component={Countdown} />
                <Route exact strict path="/shooter.html" component={Shooter} />
                <Route component={DefaultDemonstration} />
            </Switch>
            </Card>
        </div>
    );
}

export default Demonstrations;
