import React from 'react';

import {Card, H2, H3, H4, UL, HTMLTable} from '@blueprintjs/core';

function ResumeHeader(props)
{
    return (
        <H4>{props.text}</H4>
    );
}

function SkillsBlock(props)
{
    return (
        <div class="skills_block">
            <H3>{props.label}</H3>
            {props.children}
        </div>
    )
}
function ExperienceBlock(props)
{
    var title = props.title ? <H3 className="experience-block-header">{props.title}</H3> : "";
    return (
        <Card>
            <div>{props.dateRange}</div>
            {title}
            {props.employer ? <div>{props.employer}</div> : null}
            <div>{props.location}</div>
            <div>{props.children}</div>
        </Card>
    );
}

function Resume()
{
    return (
        <div style={{textAlign: "left"}}>
            <HTMLTable>
                <tr>
                    <td colspan="2">
                        <H2>Steve A. Doria</H2>
                        Software Engineer
                        <div style={{float: "right"}}>
                            stevedoria@gmail.com
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><ResumeHeader text="Summary" /></th>
                    <td><Card>Software engineer skilled in the design, implementation, and maintenance of
                        software components that are targeted at multiple platforms</Card></td>
                </tr>
                <tr>
                    <th scope="row"><ResumeHeader text="Objective" /></th>
                    <td><Card>A software engineer position in an organization interested in developing
                        quality software components within costs and on time</Card></td>
                </tr>
                <tr>
                    <th scope="row"><ResumeHeader text="Skills" /></th>
                    <td>
                        <style>{`
                            .skills_block { vertical-align: top; display: inline-block; margin: 0em 5ex 0 0; }
                        `}</style>
                        <Card>
                            <SkillsBlock label="Languages">
                                <UL>
                                    <li>C#</li>
                                    <li>C / C++</li>
                                    <li>Python</li>
                                    <li>JavaScript / TypeScript</li>
                                    <li>Rust</li>
                                    <li>PHP</li>
                                    <li>Java</li>
                                    <li>Assembly: x86, PPC, ARM</li>
                                    <li>SQL</li>
                                    <li>HTML / CSS</li>
                                </UL>
                            </SkillsBlock>
                            <SkillsBlock label="APIs">
                                <UL>
                                    <li>.NET Core Framework</li>
                                    <li>NodeJS, ReactJS, Redux</li>
                                    <li>Android</li>
                                    <li>WordPress</li>
                                </UL>
                            </SkillsBlock>
                            <SkillsBlock label="Applications">
                                <UL>
                                    <li>Microsoft Windows</li>
                                    <li>GNU/Linux: Red Hat, Ubuntu</li>
                                    <li>FreeBSD</li>
                                    <li>MySQL / MariaDB</li>
                                    <li>PostgreSQL</li>
                                    <li>SQLite</li>
                                    <li>Perforce</li>
                                    <li>gdb</li>
                                    <li>git</li>
                                </UL>
                            </SkillsBlock>
                        </Card>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><ResumeHeader text="Patents" /></th>
                    <td>
                        <Card>
                        <UL>
                            <li>US 11,595,548 B2 - Method for Using Test Color Sheets for Printing Device Calibration</li>
                            <li>US 11,350,017 B1 - Printing Device Calibration System and Methods</li>
                            <li>US 11,586,402 B2 - Contextual Features and Settings thereof for a Printing Device</li>
                            <li>US 11,423,267 B2 - Color Measurement System for Printing Devices</li>
                            <li>US 10,089,249 B2 - In-package Storing of Data for an Electronic Device</li>
                        </UL>
                        </Card>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><ResumeHeader text="Experience" /></th>
                    <td>
                        <ExperienceBlock
                            dateRange="Nov 2013 - Present"
                            title="Sr. Software Engineer"
                            employer="KYOCERA Document Solutions Development America, Inc."
                            location="Gardena, CA">
                                <UL>
                                    <li>Modify dual-licensed open source PDF interpretation software to support parallel generation and rendering of command lists</li>
                                    <li>Analyze source code using CodeSuite&reg; and authored forensic report confirming absence of copyright infringement as an expert witness certified by S.A.F.E. Corporation</li>
                                    <li>Maintain in-house software tools: image viewer that supports proprietary encodings, graphical print pipeline profiler</li>
                                </UL>
                        </ExperienceBlock>
                        <ExperienceBlock
                            dateRange="Aug 2011 - Oct 2013"
                            title="Software Engineer"
                            employer="KYOCERA Document Solutions Development America, Inc."
                            location="Gardena, CA">
                                <UL>
                                    <li>Improve runtime performance of existing functions within a constrained memory environment while maintaining stability of production code</li>
                                    <li>Implement performance improvements to libjpeg using PowerPC assembly language</li>
                                </UL>
                        </ExperienceBlock>
                        <ExperienceBlock
                            dateRange="Jan 2007 - Jun 2011"
                            title="Software Engineer"
                            employer="EmbedICs, LLC"
                            location="El Segundo, CA">
                                <UL>
                                    <li>Design, document, and implement software components for use in firmware of security ASICs</li>
                                    <li>Test and validate firmware for custom peripheral hardware components with proprietary xUnit-like unit testing framework</li>
                                    <li>Encourage the adoption of CMMI Level 2 and 3 practices</li>
                                    <li>Perform responsibilities of Build Manager role for IBM Synergy, including coordination of artifact conflict resolution, baseline creation</li>
                                </UL>
                        </ExperienceBlock>
                        <ExperienceBlock
                            dateRange="Dec 2005 - Jan 2007"
                            title="Software Engineer"
                            employer="Loosebytes, Inc."
                            location="Los Angeles, CA">
                                <UL>
                                    <li>Requirements Acquisition and Analysis</li>
                                    <li>Software Component Design</li>
                                    <li>Oversee Software Implementation, Integration, and Testing</li>
                                </UL>
                        </ExperienceBlock>
                        <ExperienceBlock
                            dateRange="Jul 2004 - Dec 2005"
                            title="Software Engineer / IT Administrator"
                            employer="Infosearch Media"
                            location="Marina del Rey, CA">
                            <UL>
                                <li>Network Administration</li>
                                <li>Data Security as well as Disaster Recovery Planning and Preparation</li>
                                <li>Engineer and Develop Web Applications</li>
                            </UL>
                        </ExperienceBlock>
                        <ExperienceBlock
                            dateRange="Feb 2003 - Feb 2004"
                            title="Software Quality Assurance"
                            employer="THQ, Inc. / Acro Service Corporation"
                            location="Pasadena, CA">
                            <UL>
                                <li>Perform functional and regression testing on software intended for different systems</li>
                                <li>Fully document procedures to reproduce discovered software faults</li>
                                <li>Member of multiple teams that had their projects certified for compliance by game console manufacturers on their first submissions</li>
                            </UL>
                        </ExperienceBlock>
                        { false &&
                        <ExperienceBlock
                            dateRange="Jul 1999 - Sep 1999"
                            title="Intern - Network Administrator"
                            employer="Digital Broadcasting OVS"
                            location="Santa Ana, CA">
                            <UL>
                                <li>Establish network topology and computing policies in a startup environment</li>
                                <li>Configure and maintain Cisco ATM Switches and WaveLan radios</li>
                                <li>Deploy and maintain Tru64 Unix and Windows NT on DEC AlphaServers</li>
                            </UL>
                        </ExperienceBlock>
                        }
                    </td>
                </tr>
                <tr>
                    <th scope="row"><ResumeHeader text="Education" /></th>
                    <td>
                        <ExperienceBlock
                            dateRange="1998 - 2002"
                            employer="University of California"
                            location="Irvine, CA">
                                <UL>
                                    <li>B.S. in Information & Computer Science with a specialization in Networks and Distributed Systems</li>
                                </UL>
                        </ExperienceBlock>
                        { false &&
                        <ExperienceBlock
                            dateRange="1994 - 1998"
                            employer="Don Bosco Technical Institute"
                            location="Rosemead, CA">
                                <UL>
                                    <li>High School Diploma with experience in Power and Transportation (Automotive) Technology</li>
                                </UL>
                        </ExperienceBlock>
                        }
                    </td>
                </tr>
            </HTMLTable>
        </div>
    );
}

export default Resume;
